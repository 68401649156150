html {
  position: relative;
  min-height: 100%;
}

body {
  font-size: 14px;
  height:100%;
  margin: 0px;
  padding: 0px;
  padding-bottom: 45px;
  /*padding-top: 70px;*/
}

footer {
  bottom: 0;
  height: 40px;
  padding: 10px;
  position: absolute;
  text-align: center;
  width: 100%;
}

button.btn, button.input-group-addon {
  cursor: pointer;
}

button.btn:disabled, button.btn[disabled], button.input-group-addon:disabled, button.input-group-addon[disabled] {
  cursor: default;
}

.button-row {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

ul.pagination {
  justify-content: center !important;
  -ms-flex-pack: center !important;
  -webkit-box-pack: center !important;
}

table.borderless td, table.borderless th {
  border: none;
}

label.col-form-label, label.form-control-label {
  font-weight: bold;
}

@media (max-width: 575px) {
  .container {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  .container-fluid {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

@media (max-width: 767px) {
  .container {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  .container-fluid {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

@media (min-width: 768px) {
  .main {
    padding-right: 10px;
    padding-left: 10px;
  }
}
